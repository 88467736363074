import React, { useState, useEffect } from "react";
import { useInterval } from "./useInterval";
import { SUMMER, WINTER } from "./App";
import {
  FormControl,
  Checkbox,
  FormLabel,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";

const secret =
  "yrmE77DhcCynykkFMCwhokicTzc74WQJnrT98mU4GFQXw2JJzdAsohF3Wd8HFj7XjbLZ4hTfNsWhjuE3mwdR4RcB2Yg7N4wZzvNq36LycPaqXbcifQ26QHPrBZQUUzFVpkyozDMewzxqYeWTckA7NoX292sdqsyevf6V6q83rDEdXGQhoB4DGs4UzhkVDERunbbt4UcN";

const commonItems = [
  "Släng kaffefilter i kaffebryggaren om den har använts",
  "Dammsuga stora huset",
  "Våttorka golv (om det har blivit fläckar på trägolvet) med golvsåpa",
  "Bastun: Sopa golvet och rista mattor",
  "Bastun: Våtskrapa bort vatten och torka av bordet",
  "Bastun: Fyll på med ny ved",
  "Bastun: Släck",
  "Lås bastun",
  "Stäng garaget",
  "Stäng alla fönster (kom ihåg fönster på loftet)",
  "Ta ur askan ur öppna spisen om man använt den.",
  "Fyll på med ved i stora huset",
  "Plocka undan disk/ur diskmaskin - om man inte hinner plocka ur maskinen så ska den iallafall vara färdigkörd.",
  "Om elementen satts på ska de stängas av - utom i badrum, som ska stå på 'frostvakt' ca 5 grader.",
  "Gör rent toalettstolen",
  "Våttorka golvet på toaletten",
  "Ta av lakan från sängarna",
  "Rensa ur all färsk mat från kylskåpet",
  "Häll i två fulla hinkar vatten i toalettstolen (för att undvika stopp)",
  "Ta med tomflaskor och pantburkar",
  "Slå av varmvattenberedaren i proppskåpet",
  "Stäng av routern",
  "Lås lillstugan",
  "Släck belysning på verandan och uppfarten",
  "Släck all belysning inne",
  "Rulla ner rullgardinerna",
  "Häng upp sarongen framför dörren",
  "Lås båda dörrarna i stora huset",
  "Sätt ner temparaturen på eller stäng av värmepumpen",
];

const summerItems = [
  "Släng sopporna i soptunnan",
  "Vrid av båda kranarna i gropen i gräsmattan  (den kranen som vetter mot lillstugan går till lillstugan den andra går till stora huset och bastun)",
];

const winterItems = [
  "Ta med soporna om sophämtning är avslutad för vintern",
  "Stäng kranen i brunnshuset och öppna ballofixen i brunnshuset (om Morgan och Filip inte är ute)",
  "När ballofixen slutat rinna i brunnshuset, stäng den ballofixen (on Morgan och Filip inte är ute)",
  "Öppna kranarna i stora huset, låt vattnet rinna ut",
  "Öppna ballofixen i gropen i gräsmattan och kontrollera att allt vatten rinner ut där. Kan ta några minuter...",
  "Vrid av kranen i gropen i gräsmattan och stäng ballofixen (den kranen som vetter mot lillstugan ska aldrig vara på under vintern)",
];

let updateInProgress = false;

const isSetsEqual = (a, b) =>
  a.size === b.size && [...a].every((value) => b.has(value));

const CheckGroup = ({ checkedItems, className, items, setChecked, title }) => {
  if (items.length === 0) {
    return null;
  }
  return (
    <div className={className} data-testid={className}>
      <FormLabel component="legend" className="margin-top">
        {title}
      </FormLabel>
      <FormGroup>
        {items.map((item) => {
          const checked = checkedItems.has(item);
          return (
            <FormControlLabel
              key={item}
              control={
                <Checkbox
                  checked={checked}
                  name={item}
                  onChange={async () => {
                    updateInProgress = true;
                    checked
                      ? checkedItems.delete(item)
                      : checkedItems.add(item);
                    const newSet = new Set(checkedItems);
                    setChecked(newSet);
                    const resp = await fetch(
                      `https://0lkr9ovi70.execute-api.eu-west-1.amazonaws.com/dev/ljustero-save?token=${secret}`,
                      {
                        method: "POST",
                        body: JSON.stringify({ item, checked: !checked }),
                      }
                    );
                    const response = await resp.json();
                    const responseSet = new Set(response);
                    if (!isSetsEqual(newSet, responseSet)) {
                      setChecked(responseSet);
                    }
                    updateInProgress = false;
                  }}
                />
              }
              label={item}
            />
          );
        })}
      </FormGroup>
    </div>
  );
};

const getItems = async () => {
  const resp = await fetch(
    `https://0lkr9ovi70.execute-api.eu-west-1.amazonaws.com/dev/ljustero-get?token=${secret}`
  );
  const response = await resp.json();
  return new Set(response);
};

async function checkForUpdates(checkedItems, setChecked) {
  if (checkedItems && !updateInProgress) {
    const serverItems = await getItems();
    if (!isSetsEqual(checkedItems, serverItems) && !updateInProgress) {
      setChecked(serverItems);
    }
  }
}

export const Checklist = ({ selectedSeason }) => {
  const seasonItems = { [WINTER]: winterItems, [SUMMER]: summerItems };
  const [checkedItems, setChecked] = useState();

  useEffect(() => {
    const run = async () => {
      const responseSet = await getItems();
      setChecked(responseSet);
    };
    run();
  }, []);

  useInterval(() => checkForUpdates(checkedItems, setChecked), 3000);

  if (!checkedItems) {
    return <div>Loading...</div>;
  }

  const items = commonItems.concat(seasonItems[selectedSeason]);
  const notChecked = items.filter((item) => !checkedItems.has(item));

  return (
    <>
      {notChecked.length === 0 && <h1>Allt klart!</h1>}
      <FormControl component="fieldset">
        <CheckGroup
          checkedItems={checkedItems}
          className="todo"
          title="Att göra"
          setChecked={setChecked}
          items={notChecked}
        />
        <CheckGroup
          checkedItems={checkedItems}
          title="Klara"
          className="done"
          setChecked={setChecked}
          items={Array.from(checkedItems)}
        />
      </FormControl>
    </>
  );
};
