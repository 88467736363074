import { useState } from "react";
import "./App.css";
import { Checklist } from "./Checklist";
import { ThemeProvider, createMuiTheme } from "@material-ui/core";

export const WINTER = "winter";
export const SUMMER = "summer";

const SEASON_TEXT = { [WINTER]: "Vinter", [SUMMER]: "Sommar" };

function SeasonButton({ season, selectedSeason, selectSeason }) {
  const selected = season === selectedSeason;
  const selectedCss = selected ? "selected-season" : "";
  return (
    <div
      className={`season-button ${selectedCss}`}
      onClick={() => {
        !selected && selectSeason(season);
      }}
    >
      {SEASON_TEXT[season]}
    </div>
  );
}

function SeasonSelector(props) {
  return (
    <div className="season-selector">
      {[
        [WINTER, SUMMER].map((season) => (
          <SeasonButton key={season} season={season} {...props} />
        )),
      ]}
    </div>
  );
}

function Header({ selectedSeason }) {
  return <h1>Ljusterö Checklista</h1>;
}

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#61dafb",
    },
    secondary: {
      main: "#FAAC61",
    },
  },
});

function App() {
  const currentMonth = new Date().getMonth();
  const beforeMarch = currentMonth < 3;
  const afterOctober = currentMonth > 10;
  const defaultSeason = beforeMarch || afterOctober ? WINTER : SUMMER;
  const [selectedSeason, selectSeason] = useState(defaultSeason);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <header>
          <Header selectedSeason={selectedSeason} />
          <SeasonSelector
            selectedSeason={selectedSeason}
            selectSeason={selectSeason}
          />
          <Checklist selectedSeason={selectedSeason} />
        </header>
      </ThemeProvider>
    </div>
  );
}

export default App;
